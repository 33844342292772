import React from "react"
import { createBrowserHistory } from 'history'

// import PDFDocument from 'pdfkit'
// import blobStream from 'blob-stream'
// import pdf from 'html-pdf'
// import html2pdf from 'html2pdf'
// import html2canvas from 'html2canvas'
// import jsPDF from 'jspdf'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from './vfs_fonts'

import { Link, navigate } from "gatsby"

import Layout from "./layout"
import SEO from "./seo"

import qs from "querystring"
import "./impact-indicator.scss"

import mailCredentials from '../../keys/mailgun-credentials.json'
import axios from "axios"

pdfMake.vfs = pdfFonts.pdfMake.vfs;


class ImpactIndicator extends React.Component {

  constructor(props) {
    super(props)
    this.node = this.props.pageContext.node
    this.tree = this.props.pageContext.tree
    this.menuOpening = false

    this.state = {
      currentSection: null,
      currentSectionStyle: '',
      uiMenuClass: 'menu-active',
      // selectedList: this.buildSections(),
      selectedList: [],
      answerDetailModalKey: '',
      showCompleteModal: false,
      success: this.props.location.search.indexOf('success') !== -1
    }

    const params = qs.parse(this.props.location.search.replace('?', ''))
    this.defaultValues = {
      name: params.name || '',
      email: params.email || '',
      project: params.project || '',
    }
    this.resultElms = {
      name: {value: params.name || ''},
      email: {value: params.email || ''},
      project: {value: params.project || ''},
    }

    this.execTree = this.execTree.bind(this)
    this.buildSections = this.buildSections.bind(this)
    this.promoteSection = this.promoteSection.bind(this)
    this.onSectionClick = this.onSectionClick.bind(this)
    this.onMenuBtnClick = this.onMenuBtnClick.bind(this)
    this.onAnswerChange = this.onAnswerChange.bind(this)
    this.getCleanSelectedList = this.getCleanSelectedList.bind(this)
    this.getNextTo = this.getNextTo.bind(this)
    this.postToGoogleForm = this.postToGoogleForm.bind(this)
    this.onSendResultSubmit = this.onSendResultSubmit.bind(this)
    this.generatePdf = this.generatePdf.bind(this)


    this.renderAsPdf = params.format && params.format === 'pdf'
    if (this.renderAsPdf) {
      document.body.innerHTML = ''
      document.body.style.backgroundColor = 'rgb(82, 86, 89)'
      setTimeout(() => {
        this.buildSections()
        this.generatePdf(pdfData => {
          const pdfDataUrl = 'data:application/pdf;base64,' + pdfData
          document.body.innerHTML = `<style>body {margin: 0}</style><iframe src=${pdfDataUrl} frameBorder="0" style="position: absolute; width: 100%; height: 100%">`
        
        })
      }, 100)
    }


  }

  componentDidMount() {
    
    const history = createBrowserHistory();

    this.setState({selectedList: this.buildSections()})
    this.unlisten = history.listen((location, action) => {
      setTimeout(() => {
        this.setState({
          success: this.props.location.search.indexOf('success') !== -1,
          selectedList: this.buildSections()
        }) 
      }, 100)
      
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  buildSections() {

    const params = qs.parse(this.props.location.search.replace('?', ''))
    let selectedList = params.selected? params.selected.split(','): []

    if (selectedList.length === 0) {
      selectedList.push(this.tree.key)
    }

    let lastPendingSection = this.tree

    this.execTree(this.tree, (s, parent) => {
      
      s.statusClass = ''
      s.navigationClass = ''
      s.extraClass = ''

      if (selectedList.indexOf(s.key) !== -1) {
        s.statusClass = 'status__pending'

        if (!s.isIndicator) {
          lastPendingSection = s
        }

        if (parent) {
          parent.navigationClass = 'success'
        }

        // initial answers of section
        s.selectedList = []
        if (s.children) {
          s.children.forEach(item => {
            if (selectedList.indexOf(item.key) !== -1) {
              s.selectedList.push(item.key)
            }
          })
        } else {
          s.statusClass = 'status__ignore'
        }

      }
    })

    lastPendingSection.extraClass = 'latest'

    // find out focus section
    let isFocus = false

    this.execTree(this.tree, (s, parent) => {
      s.focusClass = ''

      if (!isFocus && s.statusClass === 'status__pending' && s.navigationClass !== 'success') {
        s.focusClass = 'in-focus'
        isFocus = true
      } else if (isFocus) {
        s.focusClass = 'out-focus'
      }

    })

    // check if complete tree response email for receive full result
    let totalPendings = 0
    let totalCompletes = 0

    this.execTree(this.tree, (s, parent) => {
      if (s.statusClass === 'status__pending') {
        totalPendings++
        if (s.navigationClass === 'success') {
          totalCompletes++
        }
      }
    })

    const final = (totalPendings-1) === totalCompletes
    if (final) {
      lastPendingSection.extraClass += ' final'
    }

    const complete = totalPendings === totalCompletes
    if (complete) {
      setTimeout(() => {
        this.setState({showCompleteModal: true})
      }, 800)
    } else {
      this.setState({showCompleteModal: false})
    }

    return selectedList
  }



  execTree(section, callback, parent) {
    if (callback(section, parent) === false) {
      return false
    }

    if (section.children) {
      section.children.forEach(child => this.execTree(child, callback, section))
    }
  }

  promoteSection(section) {

    let inactive = !section
    this.execTree(this.tree, (s, parent) => {
      if (inactive && (parent === section || (section && s.level <= section.level))) {
        s.sectionClass = 'inactive'
      }

      if (s === section) {
        inactive = true
      }
    })

    const elm = this.refs[section.key]
    const bounding = elm.getBoundingClientRect()

    const childrenElm = elm.querySelector('.card-children')

    section.sectionClass = 'active'
    section.bounding = {
      top: elm.offsetTop,
      left: bounding.left,
      height: bounding.height,
      childrenHeight: childrenElm? childrenElm.offsetHeight: 0
    }


  }

  onSectionClick(section) {

    if (this.state.uiMenuClass === 'menu-inactive' || this.state.uiMenuClass === 'menu-inactive stable' || section.focusClass === 'out-focus' ) {
      return
    }

    this.menuOpening = true

    // this.state.activeSectionHeight = this.refs['menu-container']

    this.execTree(this.tree, (s, parent) => {
      s.sectionClass = ''
      s.bounding = {height: 0}
    })

    this.promoteSection(section)

    let newState = {
      currentSection: section,
      currentSectionStyle: ``,
      uiMenuClass: 'menu-closing', 
    }

    const container = this.refs['menu-container']

    newState.currentSectionStyle = `#${section.id} {top: ${container.scrollTop}px !important}`
    this.forceUpdate()


    setTimeout(() => {
      this.setState(newState)
      this.menuOpening = false
    }, 100)

    setTimeout(() => {
      this.setState({
        uiMenuClass: 'menu-inactive',
      })
      setTimeout(() => {
        this.setState({
          uiMenuClass: 'menu-inactive stable',
        })
      }, 100)
    }, 500)

    // check all when open leaf answer automaticaly
    if (section.isLeafAnswer) {
      section.selectedList = []
      section.children.forEach(item => {
        section.selectedList.push(item.key)
      })
    }
  }

  onMenuBtnClick(section, soft) {

    if (this.menuOpening) {
      return
    }

    let newState = {
      currentSection: null,
      currentSectionStyle: ``,
      uiMenuClass: 'menu-opening', 
    }
    if (!soft) {
      newState.selectedList = this.buildSections()
    }

    this.setState(newState)

    setTimeout(() => {
      this.setState({uiMenuClass: 'menu-active'})
    }, 800)
  }

  onSubmitBtnClick(section) {

    if (!section.selectedList.length) {
      return
    }

    setTimeout(() => {
      this.setState({
        success: false,
        selectedList: this.buildSections(),
      })
      const elm = this.refs[section.key]
      const childrenElm = elm.querySelector('.card-children')
      if (childrenElm && section.bounding.childrenHeight) {
        section.bounding.height += childrenElm.offsetHeight - section.bounding.childrenHeight
      }
      this.onMenuBtnClick(section, true)
    }, 100)
  }

  onAnswerChange(e, section, answer) {

    let selectedList = section.selectedList

    if (section.data.nextAnswerLimit === '1') { // Radio
      
      selectedList.splice(0, selectedList.length)
      selectedList.push(answer.key)

    } else { // Checkboxes
      
      const index = selectedList.indexOf(answer.key);

      if (e.target.checked) {
        if (index === -1)  {
          selectedList.push(answer.key)
        }
      } else {
        if (index !== -1)  {
          selectedList.splice(index, 1)
        }
      }
    
    }

    this.forceUpdate()
  }

  getCleanSelectedList() {
    let cleanSelectedList = [this.tree.key]
    this.execTree(this.tree, (section, parent) => {
      if (!parent || parent.selectedList.indexOf(section.key) !== -1) {
        cleanSelectedList = cleanSelectedList.concat(section.selectedList)
      } else {
        // Uncomment this line for disabled undo children
        // section.selectedList = []
      }
    })
    return Array.from(new Set(cleanSelectedList))
  }
  
  getNextTo() {
    const cleanSelectedList = this.getCleanSelectedList()
    return `/impacts/${this.node.permalink}/?selected=${cleanSelectedList.join(',')}`
  }

  getDocContentDefinition(section) {

    const spaceing = 5

    let content = [
      {
        table: {
          headerRows: 0,
          widths: [ '*' ],
          body: []
        },
        fillColor: section.level % 2 ? '#E8E8E8': '#FFFFFF',
      }
    ]

    const noBorder = [false, false, false, false]
    if (section.data) {
      content[0].table.body.push([{
        text: [
          (section.data.title || section.data.answerTitle), 
          {text: section.data.nextQuestionTitle && section.data.nextQuestionTitle !== '<BLANK>'? '\n'+section.data.nextQuestionTitle: '', fontSize: 11, color: '#777777'}
        ], 
        border: noBorder,
      }])

    }

    let children = section.children.filter(child => this.state.selectedList.indexOf(child.key) !== -1)    
    if (!section.isLeafAnswer) {
      content[0].table.body = content[0].table.body.concat(children.map((child, i) => this.getDocContentDefinition(child)))
    } else {      
      let body = [[{ text: 'ตัวชื้วัดแนะนำ'}, { text: 'หน่วยชี้วัด'}], [{text: '', border: noBorder}, {text: '', border: noBorder}]]

      body = body.concat(children.map((indicator, i) => [
        {
          text: [`${indicator.data.indicatorRecommend}`, { text: `\n${indicator.data.indicatorRecommendDefinition || ''}`, fontSize: 10, color: '#666666'}],
          border: noBorder,
        },
        {
          text: indicator.data.indicatorUnit,
          border: noBorder,
        },
      ]))

      content[0].table.body.push([{
        table: {
          headerRows: 2,
          widths: [ 340, 80 ],
          body: body
        },
        fillColor: (section.level+1) % 2 ? '#E8E8E8': '#FFFFFF',
        layout: {
          paddingLeft: (i, node) => 10,
          paddingRight: (i, node) => 10,
          paddingTop: (i, node) => 0,
          paddingBottom: (i, node) => spaceing,
          hLineWidth: (i, node) => i===0 ? 0: 1,
          vLineWidth: (i, node) => 0,
        }
      }])
    }

    content[0].layout = {
      paddingLeft: (i, node) => 10,
      paddingRight: (i, node) => 0,
      paddingTop: (i, node) => spaceing,
      paddingBottom: (i, node) => 0,
      hLineWidth: (i, node) => (i===0 || i === content[0].table.body.length ) && section.data ? 0: 1,
      vLineWidth: (i, node) => i>0 && section.data ? 0: 1,
    }

    return content
  }

  formPost(path, params, method='post') {

    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    const target = String(Math.random())

    const iframe = document.createElement('iframe')
    iframe.name = target
    iframe.style.display = 'none'

    const form = document.createElement('form')
    form.method = method
    form.action = path
    form.target = target
    form.style.display = 'none'
  
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input')
        hiddenField.type = 'hidden'
        hiddenField.name = key
        hiddenField.value = params[key]
  
        form.appendChild(hiddenField)
      }
    }
  
    document.body.appendChild(iframe)
    document.body.appendChild(form)
    form.submit()
  }

  postToGoogleForm() {
    // submit to google form
    const googleFormActionURL = 'https://docs.google.com/forms/d/e/1FAIpQLSdnxz9vT27I2rZx19a5M5e5KjaYoAfUg7cqtMkzQ2kGJNOgxA/formResponse'
    const r = this.resultElms;

    const resultMap = {
      'entry.1591412613': r.name.value,
      'entry.1839338613': r.email.value,
      'entry.165303763': r.project.value,
      'entry.770534783': r.subscribeNewsletter.checked? 'Yes': null,
      'entry.272995005': r.supportRequired.checked? 'Yes': null,
      'entry.495800445': this.node.title,
      'entry.2083271211': this.props.location.href.split('&')[0] + `&name=${encodeURIComponent(r.name.value)}&email=${encodeURIComponent(r.email.value)}&project=${encodeURIComponent(r.project.value)}&format=pdf`,
      // 'entry.1128833877': pdfData,
    }

    this.formPost(googleFormActionURL, resultMap)
  }

  generatePdf(cb) {
    // Generate PDF
    pdfMake.fonts = {
      Kanit: {
        normal: 'Kanit-Regular.ttf',
        light: 'Kanit-Light.ttf',
        bold: 'Kanit-Regular.ttf', // TODO: if use
        italics: 'Kanit-Regular.ttf', // TODO: if use
        bolditalics: 'Kanit-Regular.ttf' // TODO: if use
      }
    }

    const header = {
      text: 'ตัวชี้วัดแนะนำ และรายละเอียดนิยาม',
      fontSize: 18,
      alignment: 'center'
    }

    const info = {
        table: {
          headerRows: 0,
          widths: [ 70, '*' ],
          body: [
            ['โดย', this.resultElms.name.value],
            ['อีเมลติดต่อ', this.resultElms.email.value],
            ['ชื่อโครงการ', this.resultElms.project.value || '-']
          ]
        }
      }
  

    const docDefinition = {
      defaultStyle: {
        font: 'Kanit'
      },
      content: [header, '\n', info, '\n'].concat(this.getDocContentDefinition({children: [this.tree]}))
    }



    const pdfDocGenerator = pdfMake.createPdf(docDefinition)
    pdfDocGenerator.getBase64(cb)

  }


  onSendResultSubmit(e) {
    e.preventDefault();

    // this.setState({submitting: true})

    // Update URL
    navigate(this.props.location.pathname + this.props.location.search + '&success=true')
    this.setState({success: true, supportRequired: !!this.resultElms.supportRequired.checked})
    
    
    if (this.resultElms.email.value) {
      this.generatePdf((pdfData) => {
        // const win = window.open('', '_blank', '', true);
        // const pdfDataUrl = 'data:application/pdf;base64,' + pdfData
        // win.document.write(`<style>body {margin: 0}</style><iframe src=${pdfDataUrl} frameBorder="0" style="position: absolute; width: 100%; height: 100%">`)
      
          axios.post(mailCredentials.baseServerUrl + '/send-email-result', {
            to: this.resultElms.email.value,
            name: this.resultElms.name.value,
            pdfData: pdfData
          })
          console.log('send mail to ' + this.resultElms.email.value)
      })
    }

    this.postToGoogleForm()
  }

  // =================================================
  // Render zone
  // =================================================
  renderAnswer(section, answer, inputType, i) {

    const inputId = `${section.id}--${i}`
    return (
      <div key={`${answer.key}--${i}`} className={`answer-item`}>
        <div className={section.selectedList.indexOf(answer.key) >= 0? 'checked checkbox-container': 'checkbox-container'} >
          <div className="wrapper-checkbox">
            <label className="checkbox-label" htmlFor={inputId}>
              <input type={inputType} name={`${section.key}`} id={inputId} value={answer.key} checked={section.selectedList.indexOf(answer.key) !== -1} onChange={e => this.onAnswerChange(e, section, answer)}/>
              <span className={inputType === `radio`? 'radio checkbox-custom': 'checkbox checkbox-custom'}></span>
              <div className="answer-detail__wrapper">
                <div className="answer-detail-title">{answer.data.answerTitle}</div>
                {answer.data.answerDetail && answer.data.answerDetail !== '-' ? <button className={`answer-detail-btn btn-sm`} onClick={() => this.setState({answerDetailModalKey: answer.key})}>ความสำคัญ</button>: ``}
              </div>
            </label>
          </div>
        </div>

        <div className={`answer-detail-modal`} style={{display: this.state.answerDetailModalKey === answer.key ? 'block': 'none'}}>
          <button className={`answer-detail-modal-btn-close close-icon`} onClick={() => this.setState({answerDetailModalKey: ''})}></button>
          <div className={`answer-detail-modal-detail`}>
            {answer.data.answerDetail}
          </div>
        </div>
      </div>
    )
  }

  renderAnswerList(section) {

    const inputType = section.data.nextAnswerLimit === '1' ? 'radio': 'checkbox'

    return (
      <div className={`card-answer-list`}>
        {section.children ? section.children.map((child, i) => this.renderAnswer(section, child, inputType, i)): ''}
      </div>
    )
  }

  renderIndicator(section, indicator, i) {

    return (
      
        <div key={`${indicator.key}--${i}`} className={`indicator-item`}>
            <div className={`indicator-detail`}>
              <div className="indicator-detail-title">
                <p>{indicator.data.indicatorRecommend}</p>
              </div>
              
              <div className="indicator-detail-unit">
                <p>{indicator.data.indicatorUnit}</p>
              </div>
            </div>
        </div>
    
    )
  }

  renderIndicatorList(section) {

    return (
      <div className="card-indicator-wrapper">
        <div className={`card-indicator-heading`}>
          <div className="indicator-detail-title">
            <h3>ตัวชี้วัดแนะนำ</h3>
          </div>
          <div className="indicator-detail-unit">
            <h3>หน่วยชี้วัด</h3>
          </div>
        </div>
        {section.children ? section.children.map((child, i) => this.renderIndicator(section, child, i)): ''}
        <div className="indicator-notice">
          <small>
            *<u>หมายเหตุ</u> นิยามตัวชี้วัดแนะนำ จะอธิบายอยู่ในไฟล์ PDF ที่จะส่งให้ท่านทางอีเมลหลังจากทำแบบเรียนรู้เสร็จ
          </small>
        </div>
      </div>
   
    )
  }

  renderBody(section) {
    // Switch between answer list or indicator list
    if (!section.isLeafAnswer) {
      return this.renderAnswerList(section)
    } else {
      return this.renderIndicatorList(section)
    }
  }

  renderTree(section) {
    return (
      <div key={`wrapper---${section.key}`} className={`card-menu__section-wrapper`}>
        <div key={section.key} id={section.id} ref={section.key} className={`card-menu__section card-menu__section-${section.level-1} ${section.sectionClass} ${section.statusClass} ${section.navigationClass} ${section.focusClass} ${section.extraClass}`} style={{ top: `${section.bounding.top}px`, left: `${section.bounding.left}px` }} onClick={(e) => { e.stopPropagation(); this.onSectionClick(section)}}>
          <div className={`card-menu__menu-btn`} onClick={(e) => {e.stopPropagation(); this.onMenuBtnClick(section)}}></div>
          <h2 className={`card-menu__section-heading`}>
            {section.data.title || section.data.answerTitle }<br />
            {section.data.nextQuestionTitle && section.data.nextQuestionTitle !== '<BLANK>'? <small className={`question`}>{section.data.nextQuestionTitle}</small>: ``}

          </h2>
          <div className={`state-navigation-status`}>

            <div className={`arrow-right`}>
              {section.focusClass === 'in-focus'?
              <div>
                <div className={`text-center`}>
                  <img src={`/media/images/${this.node.permalink}-landing.png`} srcSet={`/media/images/${this.node.permalink}-landing@2x.png 2x`} alt="" />
                </div>

                {this.state.selectedList.length <= 1 ? 
                <div className={`card-menu__section-description`}>
                  <div className="card-menu__section-desc">{section.data.description}</div>
                </div>
                : ``}
                
                <div className={`btn btn-secondary`}>ถัดไป</div>
              </div>
              :
              `→`
              }
            </div>
            <div className={`checked-icon`}></div>
          </div>
          
          {this.state.selectedList.length <= 1 ? 
          <div className={`card-menu__section-description`}>
            {/* <div className="card-menu__section-image">
              <img src={`/media/images/${this.node.permalink}-landing.png`} srcSet={`/media/images/${this.node.permalink}-landing@2x.png 2x`} alt="" />
            </div>
            <div className="card-menu__section-desc">{section.data.description}</div>
            <div className="btn btn-primary">เริ่มทำแบบทดสอบ</div> */}
          </div>: ``}
          <div className={`card-body`}>
            {section.data.nextQuestionTitle && section.data.nextQuestionTitle !== '<BLANK>'? <h2>{section.data.nextQuestionTitle}</h2>: ``}
            {this.renderBody(section)}
            {this.state.currentSection === section ? 
            <div className="wrapper-footer">
              <Link to={this.getNextTo()} onClick={(e) => {e.stopPropagation(); this.onSubmitBtnClick(section)}} className={`answer-submit-btn btn btn-outline ${this.state.currentSection.selectedList.length > 0? 'active': 'inactive'}`}>
                <span className={`next`}>ข้อถัดไป</span>
                <span className={`update`}>แก้ไข</span>
              </Link>
            </div>: ''}
          </div>
          {!section.isLeafAnswer ? <div className={`card-children`}>{section.children.map(child => this.renderTree(child))}</div>: ''}
        </div>
        <div key={`dummy---${section.key}`} className={`card-menu__section-dummy`} style={{ height: `${section.bounding.height}px`}}></div>
      </div>
    )
  }

  renderResultForm() {
    return (
      <div className={this.state.success? 'hidden': ''}>
        <h2>ขอบคุณที่ทำแบบเรียนรู้</h2>
        <p>
          กรุณาระบุชื่อและอีเมลของท่าน เพื่อรับผลลัพธ์และนิยามตัวชี้วัดแนะนำฉบับเต็ม
        </p>
        <form action="" onSubmit={this.onSendResultSubmit}>
          <div className={`complete-modal-input`}>
            <input type="text" placeholder="ชื่อ-นามสกุล" name="name" defaultValue={this.defaultValues.name} required ref={node => this.resultElms.name = node}/>
          </div>
          <div className={`complete-modal-input`}>
            <input type="email" placeholder="อีเมลของคุณ" name="email" defaultValue={this.defaultValues.email} required ref={node => this.resultElms.email = node}/>
          </div>
          <div className={`complete-modal-input`}>
            <input type="text" placeholder="ชื่อโครงการ (ถ้ามี)" defaultValue={this.defaultValues.project} ref={node => this.resultElms.project = node}/>
          </div>
          <div className={`complete-modal-input`}>
            <label><input type="checkbox" defaultChecked={true} ref={node => this.resultElms.subscribeNewsletter = node}/> ต้องการรับข่าวสารจากเรา?</label>
          </div>
          <div className={`complete-modal-input`}>
            <label><input type="checkbox" defaultChecked={false} ref={node => this.resultElms.supportRequired = node}/> ต้องการรับการสนับสนุน?</label>
          </div>
          <div>
            <button className={`complete-modal-submit-btn btn btn-secondary`}>ส่งผลลัพธ์</button>
          </div>
        </form>
      </div>
    )
  }

  renderSuccess() {
    return (
      <div className={this.state.success? '': 'hidden'}>
        <h2>ส่งผลลัพธ์เรียบร้อย</h2>
        <p>
          ระบบได้ทำการส่งผลลัพธ์และนิยามตัวชี้วัดแนะนำฉบับเต็ม ไปที่อีเมลของคุณเรียบร้อยแล้ว
        </p>
        {this.state.supportRequired?
        <div>
          <a target="_blank" href={`https://mis.nia.or.th/register.aspx`} className={`complete-modal-submit-btn btn btn-secondary`}>ลงทะเบียนเพื่อขอรับการสนับสนุน</a>
        </div>
        :
        <div>
          <Link to={`/`} className={`complete-modal-submit-btn btn btn-secondary`}>กลับสู่หน้าแรก</Link>
        </div>
        }
        
        <br />
      </div>
    )
  }

  renderCompleteModal() {
    return (
      <div className={`complete-modal-wrapper ${this.state.showCompleteModal? 'bring-up': 'bring-down'}`}>
        <div className={`complete-modal ${this.state.showCompleteModal? 'slide-up': 'slide-down'}`}>
          <button className={`complete-modal-btn-close close-icon`} onClick={() => this.setState({showCompleteModal: false})}>x</button>
          <div className={`complete-modal-body`}>
            {this.renderResultForm()}
            {this.renderSuccess()}
          </div>
        </div>
      </div>
    )
  }

  render() {

    return (
      <Layout>
        <SEO title={this.node.title} />
        <div>
          <div className={`card-menu ${this.state.uiMenuClass}`} ref={`menu-container`}>
            {this.renderTree(this.tree)}
          </div>
          <style>{this.state.currentSectionStyle}</style>
          {this.renderCompleteModal()}
        </div>
      </Layout>
    )
  }
}

export default ImpactIndicator